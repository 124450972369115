<template>
  <div>
    <section>
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-7 text-center mb-5">
            <h1>LIFESTYLE</h1>
            <p>Immerse yourself in the art of indulgence, where luxury living takes centre stage.<br> Discover secluded
              holiday destinations, exquisite wines, the finest restaurants, private events, flawless interior decorating,
              inspiring landscaping designs and more. Embrace the extraordinary and delve into a lavish lifestyle.</p>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801931/Photos,54877/pic_54877510.jpg" style="border-bottom-right-radius: 30px;"
                      class="mw-100" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>New year, new luxury</h3>
                  <p>South Africa currently has one of the best performing luxury residential markets in the world. The country’s beautiful and diverse landscapes offer something for everyone. Whether you love the mountains, winelands, beach or bush, SA has exquisite luxury property options for you to choose from. And, with the country’s tourism indutry showing little chance of slowing down, it offers a prime investment opportunity for luxury buyers.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/new-year-new-luxury"
              id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/buy-a-luxury-holiday-home-in-south-africa.jpg" style="border-bottom-right-radius: 30px;"
                      class="mw-100" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>From Vineyard Retreats to Coastal Estates: Reasons to Buy a Luxury Holiday Home in South Africa</h3>
                  <p>South Africa currently has one of the best performing luxury residential markets in the world. The country’s beautiful and diverse landscapes offer something for everyone. Whether you love the mountains, winelands, beach or bush, SA has exquisite luxury property options for you to choose from. And, with the country’s tourism indutry showing little chance of slowing down, it offers a prime investment opportunity for luxury buyers.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/buy-a-luxury-holiday-home-in-south-africa"
              id="lifestyle-cta">READ MORE</a>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      property: {

      }
    };
  },
};
</script>